<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Domain -->
          <select-filter labelname="Domain" :options="domainOptions" optionsName="domainName" :value="domainSelected" @input="(newDomain) => {domainSelected = newDomain}"></select-filter>
          <!-- Date Picker -->
          <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="(newFromDate) => {fromDate = newFromDate}" @inputToDate="(newToDate) => {toDate = newToDate}"></date-range-picker>
          <!-- Only Companies checkbox -->
          <only-company :onlyCompaniesValue="onlyCompanies" @input="(newonlyCompanies) => {onlyCompanies = newonlyCompanies}"></only-company>
          <!-- Update Dashboard -->
          <vs-button type="filled" @click="updateData()" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
        </div>
      </div>
    </div>    
    <div class="vx-row">
      <!-- Visitor Location Overview -->
      <div class="vx-col w-full mb-8 xl:w-7/12">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Visitor Location Overview</h2>
             <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn" ></vs-button> -->
          </div>
          <template>
            <GChart class="g-map-location"
              :settings="{ packages: ['corechart', 'table', 'geochart'], 'mapsApiKey': googleMapApiKey }"
              type="GeoChart"
              :data="chartData"
              :options="chartOptions"         
            />
          </template>       
        </vx-card>
      </div>
      <!-- Unique Visitors by Country -->
      <div class="vx-col w-full mb-8 xl:w-5/12">
        <three-column-table
          :refValue="'tableDataUniqueVisitorsByCountry'"
          @refDataInput="(newRefData) => {refCountryData = newRefData;}"
          :tableTitle="'Unique Visitors by Country'"
          :tableRecords="uniqVisitorsByCountry"
          :maxRecordPerPage="maxRecordPerPage"
          :columnNameArray="['Country','Unique Visitors','%']"
          :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
          @clickToGetDetailData="getDetailedData"
          :getDetailedDataType="'Visitor_Company_Country_Full'"
        ></three-column-table>
      </div>
    </div>

    <div class="vx-row">
      <!-- Unique Visitors by City -->
      <div class="vx-col w-full mb-8 xl:w-7/12 lg:w-1/2">
        <three-column-table
          :refValue="'tableDataUniqueVisitorsByCity'"
          @refDataInput="(newRefData) => {refCityData = newRefData;}"
          :tableTitle="'Unique Visitors by City'"
          :tableRecords="uniqVisitorsByCity"
          :maxRecordPerPage="maxRecordPerPage"
          :columnNameArray="['City','Unique Visitors','%']"
          :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
          @clickToGetDetailData="getDetailedData"
          :getDetailedDataType="'citystate'"
        ></three-column-table>
      </div>
      <!-- Unique Visitors by Continent -->
      <div class="vx-col w-full mb-8 xl:w-5/12 lg:w-1/2">
        <three-column-table
          :refValue="'tableDataUniqueVisitorsByContinent'"
          @refDataInput="(newRefData) => {refContinentData = newRefData;}"
          :tableTitle="'Unique Visitors by Continent'"
          :tableRecords="uniqVisitorsContinent"
          :maxRecordPerPage="maxRecordPerPage"
          :columnNameArray="['Continent','Unique Visitors','%']"
          :columnSortKeyArray="['StatValueName', 'StatValue', 'StatPercentValue']"
          @clickToGetDetailData="getDetailedData"
          :getDetailedDataType="'Cont_Code'"
        ></three-column-table>
      </div>
    </div>

    <!-- Call Update Modal -->
    <analytics-view-information-modal
        :popupTitle="'Analytics - ' + category+ ' : ' + subCategory"
        :isOpen="analyticsPopupActive"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :ISPIncludeMessage="ISPIncludeMessage"
        :zoneName="zoneName"
        
        :singleCardOneIcon="'UserIcon'"
        :singleCardOneSmallTitle="analyticsUniqueVisitors.name"
        :singleCardOneBottomText="'From ' + analyticsUniqueVisitors.startValue + ' to ' + analyticsUniqueVisitors.endValue + ' unique visitors'"
        :singleCardOneUpDownValue="analyticsUniqueVisitors.percentage"
        :singleCardOneUpicon="'ArrowDownIcon'"
        :singleCardOneBgcolor="'bg-first-gradient'"
        :singleCardOneMainTextColor="'text-first'"
        :singleCardOneTextColor="'text-secondary'"
        :singleCardOneBGImg="'user.svg'"

        :singleCardTwoIcon="'EyeIcon'"
        :singleCardTwoSmallTitle="pageViews.name"
        :singleCardTwoBottomText="'From ' + pageViews.startValue + ' to ' + pageViews.endValue + ' Page Views'"
        :singleCardTwoUpDownValue="pageViews.percentage"
        :singleCardTwoUpicon="'ArrowDownIcon'"
        :singleCardTwoBgcolor="'bg-second-gradient'"
        :singleCardTwoMainTextColor="'text-second'"
        :singleCardTwoTextColor="'text-danger'"
        :singleCardTwoBGImg="'eye.png'"

        :chartTitle="'Changes Over this Period'"
        :chartType="'line'"
        :chartHeight="'350'"
        :seriesNameOne="'Unique Visitors'"
        :seriesDataOne="lineAreaChartSplineSeriesDataOne"
        :seriesNameTwo="'Page Views'"
        :seriesDataTwo="lineAreaChartSplineSeriesDataTwo"
        :optionsXaxis="lineAreaChartSplineOptionsXaxis"

        @clickToClosePopup="analyticsPopupActive = false"
    ></analytics-view-information-modal>
    
    <!-- Help popup -->
    <help-modal :popupActive='popupActive' @close="closepopup" ></help-modal>
  </div>  
  
</template>
<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";

/* Map */
import { GChart } from 'vue-google-charts'
//Help Model
import HelpModal from "../../components/modal/HelpModal";
import ThreeColumnTable from '../../components/analytics/ThreeColumnTable.vue';
import AnalyticsViewInformationModal from '../../components/modal/AnalyticsViewInformationModal.vue';

export default {
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,    
    GChart,
    HelpModal,
    ThreeColumnTable,
    AnalyticsViewInformationModal
  },
  data() {
      let uid = this.$route.params.id;
      let VVtoken = localStorage.getItem("VVtoken");
      let token = JSON.parse(VVtoken);
    return {
      refCountryData: null,
      refCityData: null,
      refContinentData: null,
      googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      popupActive: false,
      /* Filter Select */
      domainSelected: { domainName: "All" },
      //Domain dropdown options
      domainOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null, 

      //Only Companies
      onlyCompanies: false,
      
      maxRecordPerPage: 6,
      uniqVisitorsByCountry: [],
      uniqVisitorsByCity: [],
      uniqVisitorsContinent: [],

      /* Map */
      chartData: [
        ['Country', 'Unique Visitors'],
      ],
      chartOptions: {        
        'height':480,
        colorAxis:{colors: [token[uid].themeDetail.tempChartColor1]},
      },
      

      //popup data
      analyticsPopupActive:false,
      ISPIncludeMessage: "",
      dateStart: null,
      dateEnd: null,
      zoneName: null,
      category: null,
      subCategory: null,
      analyticsUniqueVisitors: {
        name: null,
        startValue: 0,  
        endValue: 0,
        percentage: null
      },
      pageViews: {
        name: null,
        percentage: null,
        startValue: 0,
        endValue: 0
      },

      // popup area and line Chart
      lineAreaChartSplineSeriesDataOne: [],
      lineAreaChartSplineSeriesDataTwo: [],
      lineAreaChartSplineOptionsXaxis: [],
    };
  },
  created() {
    this.setFromDate()
    this.setToDate()

    this.getZoneListForFilter()
    this.getCountryVisitStats()
    this.getCityVisitStats()
    this.getContinentVisitStats()
    this.getRegionMapData()
  },
  methods:{
    updateData(){
      this.refCountryData.tableDataUniqueVisitorsByCountry.currentx = 1
      this.refCityData.tableDataUniqueVisitorsByCity.currentx = 1
      this.refContinentData.tableDataUniqueVisitorsByContinent.currentx = 1
      this.refCountryData.tableDataUniqueVisitorsByCountry.searchx = ""
      this.refCityData.tableDataUniqueVisitorsByCity.searchx = ""
      this.refContinentData.tableDataUniqueVisitorsByContinent.searchx = ""
      this.getCountryVisitStats()
      this.getCityVisitStats()
      this.getContinentVisitStats()
      this.getRegionMapData()
    },
    closepopup(){
      this.popupActive = false      
    },
    //Get Domain
    getZoneListForFilter(){
      this.$vs.loading()
      this.axios.get("/ws/Zone/GetZoneListForFilter").then(response => {
        let data = response.data;

        for (let i = 0; i < data.length; i++) {
          this.domainOptions.push({
            domainName:data[i]
          })            
        }
        
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });  
    },
    //Unique Visitors By Country
    getCountryVisitStats(){
      let getCountryVisitStatsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading()
      this.axios.post("/ws/AnalyticsLocation/GetCountryVisitStats", getCountryVisitStatsParams).then(response => {
        let data = response.data;
        this.uniqVisitorsByCountry = data

				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Unique Visitors By City
    getCityVisitStats(){
      let getCityVisitStatsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading()
      this.axios.post("/ws/AnalyticsLocation/GetCityVisitStats", getCityVisitStatsParams).then(response => {
        let data = response.data;
        this.uniqVisitorsByCity = data

				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Unique Visitors By Continent
    getContinentVisitStats(){
      let getContinentVisitStatsParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading()
      this.axios.post("/ws/AnalyticsLocation/GetContinentVisitStats", getContinentVisitStatsParams).then(response => {
        let data = response.data;
        this.uniqVisitorsContinent = data

				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Visitor Location Overview
    getRegionMapData(){
      let getRegionMapDataParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading()
      this.axios.post("/ws/AnalyticsOverview/GetRegionMapData", getRegionMapDataParams).then(response => {
        let data = response.data;
        if(data.Key.length <= 40){
          this.chartData = [["Country", "Unique Visitors"]]
        }else{
          let locationMapStringData = data.Key.replace(/'/g,'"')
          this.chartData = JSON.parse(locationMapStringData)
        }
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });  
    },
    //Analytics popup for Country, City and Continent
    getDetailedData(category, subcategory) {
      this.$vs.loading();
      let uniqueVisitor = {
        Zone: this.domainSelected.domainName,
        Date_Start: this.fromDate,
        Date_End: this.toDate,
        Only_Companies: this.onlyCompanies,
        Category: category,
        SubCategory: subcategory
      };
      this.axios.post("/ws/AnalyticsDetail/GetDetailData", uniqueVisitor).then(response => {
          let data = response.data;
          this.pageViews.name = data.Page_Views.StatName;
          this.pageViews.startValue = data.Page_Views.StatStartValue;
          this.pageViews.endValue = data.Page_Views.StatEndValue;
          this.pageViews.percentage = data.Page_Views.StatChangePercent;
          this.analyticsUniqueVisitors.name = data.Unique_Visitors.StatName;
          this.analyticsUniqueVisitors.startValue = data.Unique_Visitors.StatStartValue;
          this.analyticsUniqueVisitors.endValue = data.Unique_Visitors.StatEndValue;
          this.analyticsUniqueVisitors.percentage = data.Unique_Visitors.StatChangePercent;
          this.dateStart = data.Date_Start;
          this.dateEnd = data.Date_End;
          this.zoneName = data.Zone_Name;
          this.category = data.Category;
          this.subCategory = data.SubCategory;
          this.ISPIncludeMessage = data.ISP_Include_Message;
          this.analyticsPopupActive = true;

          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          data.Default_Chart.forEach(element => {
            x.push(element.x);

            FirstGroup.push(element.FirstGroup);
            SecondGroup.push(element.SecondGroup);
          });

          this.lineAreaChartSplineSeriesDataOne = FirstGroup
          this.lineAreaChartSplineSeriesDataTwo = SecondGroup
          this.lineAreaChartSplineOptionsXaxis = x
          
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    }
  }
};
</script>
